<template>
  <div class="home">
    <!--    <banner-section :marquee="marquee" />-->
    <!--    <brands-section id="brands" />-->
    <tire />
    <news-section :news="news" v-if="i18n.locale === 'en'" />
  </div>
</template>

<script>
import i18n from "./../i18n";
import { axiosWP } from "../axios";
import NewsSection from "@/components/home/NewsSection";
import Tire from "../components/home/Tire";

export default {
  name: "home",
  created() {
    try {
      Promise.all([this.getMarquee(), this.getNews()]);
    } catch (error) {
      alert(error);
    }
  },
  methods: {
    async getMarquee() {
      // const marquee = await axiosWP.get("/marquee");
      // this.marquee = marquee.data.acf;
    },
    async getNews() {
      const news = await axiosWP.get("/oz4x4/news/" + this.postAmount);
      this.news = news.data;
    }
  },
  data: () => ({
    i18n,
    marquee: null,
    news: [],
    postAmount: 4
  }),
  components: {
    Tire,
    NewsSection
  }
};
</script>
