<!-- components/contact-details/PLContact.vue -->
<template>
  <b-container>
    <div class="contact-items">
      <div class="contact-item">
        <p>{{ $t("registeredOffice") }}:</p>
        <p>({{ $t("billingPurposes") }})</p>
        <p>
          <b>
            OZPARTS B.V.
          </b>
          <br />Weena 690,
          <br />
          3012CN Rotterdam, {{ $t("holland") }} <br />VAT: NL.8663.35.791B01
        </p>
      </div>
      <div class="contact-item">
        <p>{{ $t("officeAndWarehouse") }}:</p>
        <p class="hidden-p">_</p>
        <p>
          <b>
            OZPARTS B.V.
          </b>
          <br />Geloërveld, Blauwwater 15,
          <br />
          5951 DB Belfeld, {{ $t("holland") }}
        </p>
      </div>
      <div class="contact-item">
        <p>{{ $t("openingHours") }}:</p>
        <p class="hidden-p">_</p>
        <p>
          {{ $t("openingHours1") }}
          <br />
          <br />
          {{ $t("openingHours2") }}
        </p>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "ContactDetailsNl"
};
</script>

<style lang="css" scoped>
.contact-items {
  .contact-item {
    margin: 1.8em 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    .hidden-p {
      visibility: hidden;
    }
    p:first-of-type {
      font-family: "Michroma", sans-serif;
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-column-gap: 1em;
  }
}
</style>
