<!-- components/contact-details/PLContact.vue -->
<template>
  <b-container>
    <div class="contact-items">
      <div class="contact-item">
        <p>{{ $t("registeredOffice") }}:</p>
        <p>({{ $t("billingPurposes") }})</p>
        <p>
          <b>
            Ozparts Uk Ltd
          </b>
          <br />Prospect House, <br />1 Prospect Place, Pride Park, <br />DE24
          8HG, Derby, <br />{{ $t("greatBritan") }} <br />Tax number: 365077679
          <br />Company number: 12945327
        </p>
      </div>
      <div class="contact-item">
        <p>{{ $t("officeAndWarehouse") }}:</p>
        <p class="hidden-p">_</p>
        <p>
          <b>
            Ozparts Uk Ltd
          </b>
          <br />Ricketts Close,
          <br />
          Firs Industrial Estate,
          <br />
          Kidderminster
          <br />
          DY11 7QN, UK
          <br />
          Tel: 01296 711044
        </p>
      </div>
      <div class="contact-item">
        <p>{{ $t("openingHours") }}:</p>
        <p class="hidden-p">_</p>
        <p>
          <!-- {{ $t("openingHours1") }} -->
          <br />
          <br />
          <!-- {{ $t("openingHours2") }} -->
        </p>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "ContactDetailsUk"
};
</script>

<style lang="css" scoped>
.contact-items {
  .contact-item {
    margin: 1.8em 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    .hidden-p {
      visibility: hidden;
    }
    p:first-of-type {
      font-family: "Michroma", sans-serif;
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-column-gap: 1em;
  }
}
</style>
